import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APP_CONFIG } from "./app/core/app.constants";
import { AppConfig } from "./app/core/config/models/config";

/**
 * TODO: Remove this after UI 2.0 is default
 * https://app.clickup.com/t/8696xrz1w
 */
if (window.location.href.includes("state") && !window.location.href.includes("callback")) {
  localStorage.setItem('UI_REPORTING_V2', JSON.stringify({ isV2: true }));
}

fetch('/assets/config.json')
  .then((response) => response.json())
  .then((config) => {
    if (environment.production) {
      enableProdMode();
    }
    platformBrowserDynamic([{provide: APP_CONFIG, useValue: AppConfig.fromJson(config)}])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  })
