<main class="w-full h-screen flex justify-between gap-4 font-body overflow-hidden dark:bg-gradient-to-br dark:from-slate-900 dark:to-slate-950 bg-soft-blue">
  <section class="flex-1 flex flex-col justify-between relative overflow-y-auto p-8 xl:px-16">
    <header class="pb-4">
      <app-main-header></app-main-header>
    </header>
    <div class="flex-1 flex flex-col justify-between rounded-lg grow h-full">
      <div class="flex items-center justify-center grow">
        <div class="text-center dark:text-white text-black">
          @switch (code) {
            @case ('403') {
              <p class="text-base font-semibold text-kohort-primary mt-3">{{ code }}</p>
              <h1 data-error-title class="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">
                {{ ('error.title.' + code) | translate }}
              </h1>
              <p class="mt-6 text-lg text-white leading-7 text-gray-600">
                {{ ('error.message.' + code) | translate }}
              </p>
            }
            @case ('0') {
              <h1 data-error-title class="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">
                {{ ('error.title.' + code) | translate }}
              </h1>
              <p class="mt-6 text-lg leading-7 text-gray-600">
                {{ ('error.message.' + code) | translate }}
              </p>
            }
            @default {
              <p class="text-base font-semibold text-kohort-primary">
                {{ 'error.code' | translate: { code } }}
              </p>
              <h1 data-error-title class="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">
                {{ 'error.internalServerError' | translate }}
              </h1>
              <p class="mt-6 text-lg leading-7 text-gray-600">
                {{ 'error.internal' | translate }}
              </p>
            }
          }
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <app-button variant="primary" (click)="logout()">
              {{ 'error.button.logout' | translate }}
            </app-button>
            <app-button variant="primary" (click)="retry()">
              {{ 'error.button.retry' | translate }}
            </app-button>
          </div>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </section>
</main>
